import React from 'react';

const ManagementTeam = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3">
        {/* Left side content */}
      </div>
      <div className="w-full md:w-2/3 p-4">
        <div>
          <h2><b>Management Team</b></h2>
          <p>
            <b>Col Prof Sanjay Srivastava</b><br /><br />
            <b>Dr KJ Ramesh</b><br /><br />
            <b>Prof AK Gosain</b><br /><br />
            <b>Dr George Mathew</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
