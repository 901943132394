// src/components/NewsAndMedia.js
import React from 'react';

const NewsAndMedia = () => {
    return (
        <div className="container">
            <h1>News and Media</h1>
            <p>Latest news and media coverage.</p>
        </div>
    );
};

export default NewsAndMedia;