import React from 'react';
import './ObservingSystem.css';
import { FaSatelliteDish, FaMapMarkedAlt, FaCloudSun, FaGlobe, FaDatabase, FaSatellite } from 'react-icons/fa';

const ImportantLinks = () => {
  const links = [
    { name: 'Radar - Delhi', url: 'https://mausam.imd.gov.in/imd_latest/contents/index_radar.php', icon: <FaMapMarkedAlt /> },
    { name: 'Satellite Services', url: 'https://mausam.imd.gov.in/responsive/satellite.php', icon: <FaSatelliteDish /> },
    { name: 'AWS and ARG Network', url: 'http://aws.imd.gov.in:8091/', icon: <FaCloudSun /> },
    { name: 'Public Weather Observation', url: 'https://city.imd.gov.in/citywx/crowd/enter_th_datag.php', icon: <FaGlobe /> },
    { name: 'NASA Weather Satellite Images', url: 'https://weather.ndc.nasa.gov/GOES/', icon: <FaSatellite /> },
    { name: 'Emergency Management DB', url: 'https://ndem.nrsc.gov.in/login.php', icon: <FaDatabase /> }
  ];

  return (
    <div className="important-links-container">
      <h2 className="important-links-title">Essential Weather Resources</h2>
      <div className="links-grid">
        {links.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="link-card">
            <div className="link-content">
              <div className="link-icon">{link.icon}</div>
              <div className="link-text">{link.name}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ImportantLinks;
