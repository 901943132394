import React from 'react';
import './statesteam.css'; // Ensure you have a CSS file for styling

const StatesTeam = () => {
  return (
    <div className="states-team-container">
      <div className="states-team-content">
        <h2><b>States Team</b></h2> 
        <p><strong>East India and Jharkhand</strong> – Mr Daya Shankar Mishra, Col BN Dubey</p>
        <p><strong>Odisha</strong> – Maj MC Sahoo, Ms Kanika Bose</p>
        <p><strong>West Bengal</strong> – Mr Nilkantha Chatterjee</p>
        <p><strong>Chhattisgarh</strong> – Maj Pravin Singh</p>
        <p><strong>Bihar</strong> – Dr AK Verma</p>
        <p><strong>Assam</strong> – IIT Guwahati, CU, Kajiranga, Jorhat</p>
        <p><strong>Gujarat</strong> – Gujarat Institute of Disaster Management</p>
        <p><strong>Maharashtra</strong> – Mr Umesh Unnikrishnan</p>
        <p><strong>Madhya Pradesh</strong> – Dr Rakesh Dubey, CEO ERDMP</p>
        <p><strong>Uttar Pradesh</strong> – Dr Ashish Kumar Singh</p>
        <p><strong>Uttarakhand</strong> – Dr PC Joshi</p>
        <p><strong>Andhra Pradesh</strong> – Dr A Kumar</p>
        <p><strong>Kerala</strong> – Dr K Pradeep</p>
        <p><strong>Telangana</strong> – Dr J Reddy</p>
        <p><strong>Karnataka</strong> – Dr KJ Ramesh</p>
      </div>
    </div>
  );
};

export default StatesTeam;
