import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import axios from 'axios';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [newsHeadline, setNewsHeadline] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    // Fetch news using News API
    const fetchNews = async () => {
      const apiKey = 'a7b2b4d630ce479a943889912cf49d6a'; // Replace with your News API key
      const url = `https://newsapi.org/v2/everything?q=weather+India&apiKey=${apiKey}&sortBy=publishedAt`;

      try {
        const response = await axios.get(url);
        const articles = response.data.articles;
        if (articles.length > 0) {
          setNewsHeadline(articles[0].title); // Set the first news headline
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const openAajTakWeather = (event) => {
    event.preventDefault();
    window.open('https://www.aajtak.in/topic/weather', '_blank');
    setIsCollapsed(true);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isCollapsed ? 'false' : 'true'}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${isCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/' ? 'active' : ''}`} to="/" onClick={() => setIsCollapsed(true)}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/about/who-we-are' ? 'active' : ''}`} to="/about/who-we-are" onClick={() => setIsCollapsed(true)}>
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/services' ? 'active' : ''}`} to="/services" onClick={() => setIsCollapsed(true)}>
                Forecasts & Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/observing-system' ? 'active' : ''}`} to="/observing-system" onClick={() => setIsCollapsed(true)}>
                Observing System
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/lightning-resilient-india-campaign' ? 'active' : ''}`} to="/lightning-resilient-india-campaign" onClick={() => setIsCollapsed(true)}>
                Lightning Resilient India Campaign
              </Link>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activeLink === '/cropc-blog' ? 'active' : ''}`} href="https://sanjaysrivastava.blog" target="_blank" rel="noopener noreferrer" onClick={() => setIsCollapsed(true)}>
                CROPC Blog
              </a>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/lightning-report' ? 'active' : ''}`} to="/lightning-report" onClick={() => setIsCollapsed(true)}>
                Lightning Report
              </Link>
            </li>
            <li className="nav-item">
              <a 
                className={`nav-link ${activeLink === '/news-and-media' ? 'active' : ''}`} 
                href="https://www.aajtak.in/topic/weather"
                onClick={openAajTakWeather}
              >
                News & Media
              </a>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activeLink === '/contact' ? 'active' : ''}`} to="/contact" onClick={() => setIsCollapsed(true)}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
