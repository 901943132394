import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import logo from '../assets/cropc.png';
import axios from 'axios'; // Import axios for making API requests
import { Link } from 'react-router-dom';

const Header = () => {
  const [weather, setWeather] = useState(null);
  const [newsHeadlines, setNewsHeadlines] = useState([]);
  const [rssFeeds, setRssFeeds] = useState([]);
  const [currentHeadlineIndex, setCurrentHeadlineIndex] = useState(0);
  const [currentRSSIndex, setCurrentRSSIndex] = useState(0);
  const apiKey = 'db7293eb399b7480619361d60bd6d3ea'; // Replace with your OpenWeatherMap API key
  const animationIntervalRef = useRef(null);
  const rssAnimationIntervalRef = useRef(null);

  useEffect(() => {
    // Get user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        fetchWeather(latitude, longitude);
      });
    }
  }, []);

  const fetchWeather = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}`
      );
      const data = await response.json();
      setWeather(data);
    } catch (error) {
      console.error('Error fetching weather data: ', error);
    }
  };

  useEffect(() => {
    // Fetch news using News API
    const fetchNews = async () => {
      const apiKey = 'a7b2b4d630ce479a943889912cf49d6a'; // Replace with your News API key
      const url = `https://newsapi.org/v2/everything?q=climate+India&apiKey=${apiKey}&sortBy=publishedAt`;

      try {
        const response = await axios.get(url);
        const articles = response.data.articles;
        if (articles.length > 0) {
          setNewsHeadlines(articles.slice(0, 2).map((article) => article.title)); // Get first 2 headlines
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews(); // Initial fetch on component mount
    const intervalId = setInterval(fetchNews, 3600000); // Update every hour (3600000 milliseconds)

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Fetch RSS feeds
    const fetchRSSFeeds = async () => {
      const url = 'https://api.allorigins.win/get?url=' + encodeURIComponent('https://cap-sources.s3.amazonaws.com/in-imd-en/rss.xml');

      try {
        const response = await axios.get(url);
        const parser = new DOMParser();
        const xml = parser.parseFromString(response.data.contents, 'application/xml');
        const items = xml.querySelectorAll('item');
        const feeds = [];
        
        items.forEach(item => {
          const description = item.querySelector('description')?.textContent || '';
          const link = item.querySelector('link')?.textContent || '';
          feeds.push({ description, link });
        });

        setRssFeeds(feeds.slice(0, 4)); // Take first 4 items
      } catch (error) {
        console.error('Error fetching RSS feeds:', error);
      }
    };

    fetchRSSFeeds(); // Initial fetch on component mount
    const intervalId = setInterval(fetchRSSFeeds, 3600000); // Update every hour (3600000 milliseconds)

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Start animation for news
    animationIntervalRef.current = setInterval(() => {
      setCurrentHeadlineIndex((prevIndex) => (prevIndex + 1) % newsHeadlines.length);
    }, 4000);

    // Clear interval on unmount
    return () => clearInterval(animationIntervalRef.current);
  }, [newsHeadlines]);

  useEffect(() => {
    // Start animation for RSS
    rssAnimationIntervalRef.current = setInterval(() => {
      setCurrentRSSIndex((prevIndex) => (prevIndex + 1) % rssFeeds.length);
    }, 4000);

    // Clear interval on unmount
    return () => clearInterval(rssAnimationIntervalRef.current);
  }, [rssFeeds]);

  return (
    <header className="header">
      <div className="header-top">
        <div className="contact-info">
          <span>Call Us: <a href="tel:+916767860470">+91-767860470</a></span>
          <span>Email Us: <a href="mailto:cropcn@gmail.com">cropcn@gmail.com</a></span>
        </div>
        <div className="social-icons">
          <a href="https://www.facebook.com/p/Climate-Resilient-Observing-Systems-Promotion-Council-Cropc-100069992391844/?paipv=0&eav=AfYV-cUnmH5PLRCBC_yWP63zLbkPPd-bexaNLMW1dZc1GvhiQLJ0TDHnJRfsgehznzA&_rdr">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://x.com/cropclightning">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="https://www.youtube.com/@cropc_lightning">
            <i className="fa fa-youtube"></i>
          </a>
          <a href="https://www.instagram.com/cropc_lightning_ngo/">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/cropc-inter-governmental-scientific-organization-358031247/">
            <i className="fa fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="header-bottom">
        <div className="left-container">
          <Link to="/"> {/* Wrap the logo in a Link component */}
            <img src={logo} alt="Logo" className="logo" style={{ width: '100px', height: 'auto' }} />
          </Link>
          <div className="site-title-container">
            <h1 className="site-title">Climate Resilient Observing Systems Promotion Council (CROPC)</h1>
            <p className="site-motto">Weather simplified for humanity</p>
          </div>
        </div>
        <div className="news-weather-container">
          {newsHeadlines.length > 0 && (
            <div className="news-headline">
              <div className="alert alert-warning" role="alert">
                Latest News: {newsHeadlines[currentHeadlineIndex]}
              </div>
            </div>
          )}
          {weather && (
            <div className="weather-info">
              <div className="weather-details">
                <p>Weather right now in your city:</p>
                <p>{weather.name}: {weather.main.temp}°C, {weather.weather[0].description}</p>
              </div>
              <img
                src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                alt="Weather icon"
                className="weather-icon"
              />
            </div>
          )}
        </div>
      </div>
      <div className="rss-feed-container">
        {rssFeeds.length > 0 && (
          <div className="rss-feed alert alert-info" role="alert">
            <div className="latest-alerts">
              Latest Alerts:
            </div>
            <marquee>
              {rssFeeds.map((feed, index) => (
                <span key={index}>
                  <a href={feed.link}>{feed.description}</a>
                </span>
              ))}
            </marquee>
          </div>
        )}
        <div className="latest-conference">
          <div className="conference-content">
            <span className="new-badge">New</span>
            <a href="https://mail4rajhans.wixsite.com/website" target="_blank" rel="noopener noreferrer">
              Latest Conference: International Conference on Lightning and Extreme Weather Events 
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

