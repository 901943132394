import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import './Home.css';
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';
import banner4 from '../assets/banner4.jpg';
import axios from 'axios'; // Import axios for making API requests

// Import patron images
import patron1 from '../assets/patrons/Dr.Aditi_Kapoor-IFRC.jpg';
import patron2 from '../assets/patrons/AnilKumarSinha-IAS-Retd.jpeg';
import patron3 from '../assets/patrons/KJ-Ramesh.jpg';
import patron4 from '../assets/patrons/Prof_AshvinKGosain-in.ehli.jpg';
import patron5 from '../assets/patrons/ProfSKDash-IITDelhi-IMS.jpg';
import patron6 from '../assets/patrons/Dr._VS_Prakash,Member-LDA.jpg';
import patron7 from '../assets/patrons/dgm_imd.jpg';
import patron8 from '../assets/patrons/Col_Sanjay_Srivastava.jpg';
import patron9 from '../assets/patrons/NM_Prusty,President,HAI.jpg';
import patron10 from '../assets/patrons/PP_Shrivastav-IAS_Retd.jpg';

// Import service images
import earlyWarningImage from '../assets/services/hydrology.jpg';
import communityResilienceImage from '../assets/services/climate-change.jpg';
import researchDevelopmentImage from '../assets/services/environment.jpg';
import policyAdvocacyImage from '../assets/services/agriculture.jpg';

const patrons = [
  { src: patron1, name: 'Dr. Aditi Kapoor', title: 'IFRC' },
  { src: patron2, name: 'Anil Kumar Sinha', title: 'IAS (Retd.)' },
  { src: patron3, name: 'Dr. K.J. Ramesh', title: 'Former Director General of IMD' },
  { src: patron4, name: 'Prof. Ashvin K. Gosain', title: 'IIT Delhi' },
  { src: patron5, name: 'Prof. S.K. Dash', title: 'IIT Delhi & IMS' },
  { src: patron6, name: 'Dr. V.S. Prakash', title: 'Member, KSLDA' },
  { src: patron7, name: 'Dr. Mrutyunjay Mohapatra', title: 'Director General of IMD' },
  { src: patron8, name: 'Col. Sanjay Srivastava', title: 'Chairman, CROPC' },
  { src: patron9, name: 'N.M. Prusty', title: 'President, HAI' },
  { src: patron10, name: 'P.P. Shrivastav', title: 'IAS (Retd.)' },
];

const services = [
  { 
    title: 'Hydrology', 
    description: 'CROPC deals with developing hydrological models for surface water and ground water modelling, modelling of snow and glacier melt runoff, modelling and water profile simulations, flood forecasting, inundation, flood management, sediment yield estimation, thermal and water resources projects etc.', 
    image: earlyWarningImage 
  },
  { 
    title: 'Climate Change', 
    description: 'CROPC is involved in impact assessment of climate change on water, agriculture and environment sectors, quantifying on availability of water for drinking, irrigation, hydropower generation and industrial use of water in space and time using GCMs/RCMs output data, possible changes in severity of droughts and intensity of floods etc.', 
    image: communityResilienceImage 
  },
  { 
    title: 'Environment', 
    description: 'CROPC has expertise to estimate environmental flows for hydropower projects considering present and future social, agricultural and aquatic scenarios, impacts of various man-made actions on the environment including the impact assessment of point and non-point source pollutants, conservation of natural environment etc.', 
    image: researchDevelopmentImage 
  },
  { 
    title: 'Agriculture', 
    description: 'CROPC is able to make agricultural prediction using the crop growth models and the local weather parameters, study of water-agriculture-energy-climate change nexus, adaptation of agriculture practices under projected climate scenarios, modelling of nutrients, pesticides and fertilizer movement in agriculture area.', 
    image: policyAdvocacyImage 
  },
];

const loremIpsumNews = [
  { text: "India has no role in Bangladesh flood, Dumbur dam opens automatically for last 50 years: Tripura official", link: "https://www.downtoearth.org.in/natural-disasters/india-has-no-role-in-bangladesh-flood-dumbur-dam-opens-automatically-for-last-50-years-tripura-official" },
  { text: "Tripura’s floods ‘classic example’ of climate change intensifying extreme weather events: Experts", link: "https://www.downtoearth.org.in/natural-disasters/tripuras-floods-classic-example-of-climate-change-intensifying-extreme-weather-events-experts" },
];

const Home = () => {
  const newsContentRef = useRef(null);

  useEffect(() => {
    const newsContent = newsContentRef.current;

    if (newsContent) {
      const scrollHeight = newsContent.scrollHeight;
      const clientHeight = newsContent.clientHeight;

      // Set the animation duration based on content length (slower speed)
      const duration = scrollHeight / 20; // Adjusted for slower speed
      newsContent.style.setProperty('--scroll-duration', `${duration}s`);

      // Reset scroll position when it reaches the top
      const handleScroll = () => {
        if (newsContent.scrollTop === 0) {
          newsContent.scrollTop = scrollHeight; // Reset to bottom
        }
      };

      newsContent.addEventListener('scroll', handleScroll);

      return () => {
        newsContent.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const images = [
    { src: banner4, alt: 'Banner 4' },
    { src: banner1, alt: 'Banner 1' },
    { src: banner2, alt: 'Banner 2' },
    { src: banner3, alt: 'Banner 3' },
  ];

  return (
    <div className="home-container">
      {/* Weather Information Section */}
      <div className="weather-info-container alert alert-info" role="alert">
        <div className="weather-info-title">
          Weather Information:
        </div>
        <div className="weather-info-links">
          <a href="https://mausam.imd.gov.in/imd_latest/contents/subdivisionwise-warning.php">Weather Warnings</a>
          <a href="https://mausam.imd.gov.in/responsive/all_india_forcast_bulletin.php">All India Weather Bulletin</a>
          <a href="https://internal.imd.gov.in/pages/city_weather_main_mausam.php">Urban Meteorological Services</a>
          <a href="https://mausam.imd.gov.in/imd_latest/contents/index_radar.php">Radar Images</a>
          <a href="https://mausam.imd.gov.in/responsive/satellite.php">Satellite Images</a>
          <a href="https://rsmcnewdelhi.imd.gov.in/">Cyclone Information</a>
        </div>
      </div>

      {/* Existing Carousel Section */}
      <div className="carousel-container">
        <div className="carousel-section">
          <Carousel className="home-carousel">
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 fixed-size-img"
                  src={image.src}
                  alt={image.alt}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        {/* News Panel Section */}
        <div className="news-panel">
          <h2>Latest Climate and Disaster News</h2>
          <div className="news-content-wrapper" style={{ overflow: 'hidden', height: '100px' }}>
            <div className="news-content" ref={newsContentRef} style={{ animation: 'scroll-up var(--scroll-duration) linear infinite' }}>
              {loremIpsumNews.map((news, index) => (
                <div className="news-item" key={index}>
                  <a href={news.link} target="_blank" rel="noopener noreferrer">
                    {news.text}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CROPC Info and Patrons Section */}
      <div className="patrons-organ-info-container">
        {/* CROPC Section */}
        <div className="cro-section">
          <img src={earlyWarningImage} alt="CROPC Image" className="cro-section-image" /> 
          <h1>CROPC</h1>
          <p style={{color: 'white'}}>
            Climate Resilient Observing-Systems Promotion Council – abbreviated as CROPC has been formed as Nonprofit organization under section 8 of Company Act 2013 to act as an interface between Central Early warning agencies, line departments, state, Community, NGOs and other stakeholders.
          </p>
        </div>

        {/* Patrons Section */}
        <div className="patrons-section">
          <h2 className="patrons-header">Our Patrons</h2>
          <Carousel className="carousel-patrons">
            {patrons.map((patron, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block fixed-size-img"
                  src={patron.src}
                  alt={patron.name}
                />
                <div className="carousel-caption-patron">
                  <h3>{patron.name}</h3>
                  <p>{patron.title}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>

      {/* Our Services Section */}
      <div className="services-section">
        <h2>Our Services</h2>
        <div className="services-list">
          {services.map((service, index) => (
            <div className="service-item" key={index}>
              {service.image && <img src={service.image} alt={service.title} className="service-image" />}
              <h3><strong>{service.title}</strong></h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// CSS for scrolling animation
const styles = `
@keyframes scroll-up {
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
}
`;

// Append styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Home;
