import React from 'react';

const GoverningCouncil = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/3">
        {/* Left side content */}
      </div>
      <div className="w-full md:w-2/3 p-4">
        <div>
          <h2><b>Governing Council / Patrons</b></h2>
          <p>
            <b>Sri P.P. Shrivastav, IAS(Retd)</b><br />
            Member NDMA Advisory Committee<br /><br />
            
            <b>Sri Anil Kumar Sinha, IAS(Retd)</b><br />
            Fellow ICIMOD<br /><br />
            
            <b>Dr M. Mohapatra, DG</b><br />
            Indian Meteorological Department<br /><br />
            
            <b>Dr. K.J. Ramesh</b><br />
            Former DG, IMD<br /><br />
            
            <b>Prof A.K.Gosain</b><br />
            IIT Delhi<br /><br />
            
            <b>Dr. VS Prakash</b><br />
            Founder Director KSNDMC<br /><br />
            
            <b>Prof. S.K. Dash, CAS, IIT Delhi</b><br />
            President IMS<br /><br />
            
            <b>Dr Aditi Kapoor</b><br />
            Regional Advisor, IFRC<br /><br />
            
            <b>Prof PK Joshi</b><br />
            Chairman, SCDR, JNU<br /><br />
            
            <b>Dr. N.M. Prusty</b><br />
            Chairman, HAI<br /><br />
            
            <b>Sri. Thomas Cherian</b><br />
            CEO, World Vision India<br /><br />
            
            <b>Col Sanjay Srivastava</b><br />
            Chairman, CROPC<br /><br />
            
            <b>Mrs. Anupama Prasad</b><br />
            Director, CROPC<br /><br />
            
            <b>Sri Samam Srivastava</b><br />
            Director, CROPC
          </p>
        </div>
      </div>
    </div>
  );
};

export default GoverningCouncil;
