import React from 'react';

const Mission = () => {
  return (
    <div>
      <h2><b>Our Mission</b></h2>
      <h3>Our Approach</h3>
      <p>
      CROPC’s mission is to bring down the mortality  due to lightning and other disasters through increased community first approach, policy interventions, scientific innovations, academic collaborations, affordable and accessible solutions, standardized  instrumentation ,prompt dissemination of early warning to last mile through committed  volunteers in user friendly manner, create a culture of safety through active  capacity building  through education, awareness & training  and guidance on installation of  lightning safety devices  in critical  infrastructures like school, hospital. community centers etc.
      </p>
    </div>
  );
};

export default Mission;
