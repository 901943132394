import React from 'react';
import './LightningResilientIndiaCampaign.css';
import lightning1 from '../assets/lightning1.png';
import lightning2 from '../assets/lightning2.png';
import lightning3 from '../assets/lightning3.jpg'; // New import for the new conference image
import { Calendar, BookOpen } from 'lucide-react';
import pdf1 from '../assets/Annual_Lightning_Conference_2023.pdf';
import pdf2 from '../assets/Annual_Lightning_Report_2022-2023.pdf';
// import pdf3 from '../assets/International_Lightning_Conference_2024.pdf'; // Uncomment when PDF is available

const NewsItem = ({ image, alt, title, date, description, link }) => (
  <div className="lri-news-item">
    <div className="lri-news-image-container">
      <img src={image} alt={alt} className="lri-news-image" />
      <div className="lri-news-date">
        <Calendar size={16} />
        <span>{date}</span>
      </div>
    </div>
    <div className="lri-news-content">
      <h2 className="lri-news-title">{title}</h2>
      <p className="lri-news-description">{description}</p>
      <a href={link} className="lri-news-link" target="_blank" rel="noopener noreferrer">
        <BookOpen size={18} />
        <span>Read Full Article</span>
      </a>
    </div>
  </div>
);

const LightningResilientIndiaCampaign = () => {
  const newsItems = [
    {
      image: lightning3, // New image for International Conference
      alt: "International Lightning Conference 2024",
      title: "International Lightning Conference 2024",
      date: "November, 2024",
      description: "Join us for the International Lightning Conference on Fulminology - The science of Lightning, Impact on Global Warming and Climate Change and Lightning Resilient Infrastructure. Venue: IMD & CROPC Centres, New Delhi.",
      link: "https://mail4rajhans.wixsite.com/6th-national-lightni"
    },
    {
      image: lightning1,
      alt: "Annual Lightning Conference 2023",
      title: "Annual Lightning Conference 2023",
      date: "August 28-29, 2023",
      description: "Join us for the latest insights on the surge in lightning occurrences and fatalities. Learn about cutting-edge research from the Lightning Detection Network (LDN) and strategies for mitigating risks.",
      link: pdf1 // Updated link
    },
    {
      image: lightning2,
      alt: "Annual Lightning Report 2021-2022",
      title: "Annual Lightning Report 2021-2022",
      date: "July 15, 2023",
      description: "Discover how fulminology has evolved in India, placing lightning at the forefront of disaster risk reduction (DRR) efforts. Explore key findings and recommendations from our comprehensive annual report.",
      link: pdf2 // Updated link
    }
  ];

  return (
    <div className="lri-campaign-container">
      <h1 className="lri-campaign-title">Lightning Resilient India Campaign</h1>
      <div className="lri-news-grid">
        {newsItems.map((item, index) => (
          <NewsItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default LightningResilientIndiaCampaign;