import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './About.css';
import { Link, Outlet, useLocation } from 'react-router-dom'; // Import Link and Outlet

const About = () => {
  const whoWeAreRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const visionRef = useRef(null);
  const missionRef = useRef(null);
  const governingCouncilRef = useRef(null);
  const chairmanRef = useRef(null);
  const managementTeamRef = useRef(null);
  const statesTeamRef = useRef(null);
  const location = useLocation(); // Get the current route

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="container about-container">
      <h1 className="text-left my-4 about-title">About Us</h1>
      <section className="about-content">
        <div className="row">
          <div className="col-lg-3 col-md-12 about-left">
            <div className="about-section">
              <Link to="who-we-are" onClick={() => scrollToRef(whoWeAreRef)} className="about-section-title">Who We Are</Link>
            </div>
            <div className="about-section">
              <Link to="what-we-do" onClick={() => scrollToRef(whatWeDoRef)} className="about-section-title">What We Do</Link>
            </div>
            <div className="about-section">
              <Link to="vision" onClick={() => scrollToRef(visionRef)} className="about-section-title">Vision</Link>
            </div>
            <div className="about-section">
              <Link to="mission" onClick={() => scrollToRef(missionRef)} className="about-section-title">Mission</Link>
            </div>
            <div className="about-section">
              <Link to="governing-council" onClick={() => scrollToRef(governingCouncilRef)} className="about-section-title">Governing Council</Link>
            </div>
            <div className="about-section">
              <Link to="chairman" onClick={() => scrollToRef(chairmanRef)} className="about-section-title">Chairman</Link>
            </div>
            <div className="about-section">
              <Link to="management-team" onClick={() => scrollToRef(managementTeamRef)} className="about-section-title">Management Team</Link>
            </div>
            <div className="about-section">
              <Link to="states-team" onClick={() => scrollToRef(statesTeamRef)} className="about-section-title">States Team</Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 about-right">
            <Outlet /> {/* Renders the right component based on the route */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
