import React from 'react';
import './vision.css'; // Assuming you have a CSS file for styling

const Vision = () => {
  return (
    <div className="vision-container">
      <h2><b>Our Vision</b></h2>
      <div className="vision-text">
        Our vision is to create a weather resilient Society by a research and development based credible knowledge driven development and disaster management through a comprehensive, multipronged innovative approach and collective action thereby taking Multi Hazard Early Warning to the community up to last mile, capacity building and creating hazard resilient infrastructures with focus on local disaster hotspots in accordance with the Sendai Framework for Disaster Risk Reduction(SF DRR) and zero tolerance towards avoidable disasters.
      </div>
    </div>
  );
};

export default Vision;
