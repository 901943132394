import React from 'react';
import './MapModal.css'; // Ensure you create this CSS file

const MapModal = ({ onClose }) => {
  return (
    <div className="map-modal">
      <div className="map-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <iframe 
          src="https://monsoonforest.gitlab.io/nasa-landslide-nowcast/" 
          title="Landslide Nowcast Map" 
          width="100%" 
          height="500px" 
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default MapModal;