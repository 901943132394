import React, { useState } from 'react';
import './Services.css';
import MapModal from './MapModal'; // Import the MapModal component

const Service = ({ icon, title, color, onClick, link }) => (
  <div className={`service ${color}`} onClick={onClick || (() => window.open(link, '_blank'))}>
    <div className="service-icon">{icon}</div>
    <div className="service-title">{title}</div>
    <div className="service-read-more">Read More →</div>
  </div>
);

const Forecast = ({ title, link }) => (
  <a href={link} className="forecast">
    <span className="forecast-icon">→</span>
    <span className="forecast-title">{title}</span>
  </a>
);

const Services = () => {
  const [isMapModalOpen, setMapModalOpen] = useState(false);

  const services = [
    { icon: '🌧️', title: 'Landslide Nowcast', color: 'blue', onClick: () => setMapModalOpen(true) },
    { icon: '☁️', title: 'RAINFALL INFORMATION', color: 'blue', link: 'https://mausam.imd.gov.in/responsive/rainfallinformation.php' },
    { icon: '☂️', title: 'MONSOON INFORMATION', color: 'gold', link: 'https://mausam.imd.gov.in/responsive/monsooninformation.php' },
    { icon: '🌀', title: 'CYCLONE INFORMATION', color: 'green', link: 'https://mausam.imd.gov.in/responsive/cycloneinformation.php' },
    { icon: '🚜', title: 'AGROMET ADVISORY SERVICES', color: 'blue', link: 'https://mausam.imd.gov.in/responsive/agromet_adv_ser_state_current.php' },
    { icon: '📈', title: 'CLIMATE SERVICES', color: 'gold', link: 'https://mausam.imd.gov.in/responsive/climate_services.php' },
    { icon: '🏙️', title: 'URBAN METEOROLOGICAL SERVICES', color: 'green', link: 'https://internal.imd.gov.in/pages/city_weather_main_mausam.php' },
    { icon: '✈️', title: 'AVIATION SERVICES', color: 'blue', link: 'https://camd.imd.gov.in/' },
    { icon: '📊', title: 'CLIMATE HAZARD & VULNERABILITY ATLAS', color: 'gold', link: 'https://imdpune.gov.in/hazardatlas/index.html' },
    { icon: '🌍', title: 'GEOSPATIAL SERVICES', color: 'green', link: 'http://imdgeospatial.imd.gov.in/' },
  ];

  const forecasts = [
    { title: 'Short to Medium Range Model Guidance', link: 'https://nwp.imd.gov.in/gfsproducts_cycle00_mausam.php' },
    { title: 'Extended Range Outlook', link: 'https://internal.imd.gov.in/section/nhac/dynamic/extended.pdf' },
    { title: 'Seasonal Forecast', link: 'https://mausam.imd.gov.in/responsive/seasonal_forecast.php' },
    { title: 'Quantitative Precipitation Forecast', link: 'https://mausam.imd.gov.in/responsive/quantPrecipForecast.php' },
    { title: 'All India Weather Forecast Bulletin', link: 'https://mausam.imd.gov.in/responsive/all_india_forcast_bulletin.php' },
    { title: '7-day Sub-Divisional Rainfall Forecast', link: 'https://mausam.imd.gov.in/responsive/7d_subdivisional_rf.php' },
    { title: '5-day District-Wise Rainfall Forecast', link: 'https://mausam.imd.gov.in/responsive/5d_statewisedistricts_rf_forecast.php' },
    { title: 'Tourism Forecast', link: 'https://internal.imd.gov.in/pages/tourism_forecast_mausam.php' },
    { title: 'Interactive Track of Cyclone', link: 'https://ddgmui.imd.gov.in/dwr_img/GIS/cyclone.html' },
    { title: 'Public Observation', link: 'https://city.imd.gov.in/citywx/crowd/enter_th_datag.php' },
    { title: 'Latest CAP Alerts', link: 'https://cap-sources.s3.amazonaws.com/in-imd-en/rss.xml' },
    { title: 'Flash Flood Bulletin', link: 'https://mausam.imd.gov.in/responsive/flashFloodBulletin.php' },
  ];

  return (
    <div className="services-container">
      <h2>OUR SERVICES</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <Service key={index} {...service} />
        ))}
      </div>
      {isMapModalOpen && <MapModal onClose={() => setMapModalOpen(false)} />}
      <h2>FORECASTS</h2>
      <div className="forecasts-grid">
        {forecasts.map((forecast, index) => (
          <Forecast key={index} {...forecast} />
        ))}
      </div>
    </div>
  );
};

export default Services;