import React from 'react';
import './LightningReport.css'; // Make sure to create this CSS file
import AnnualReport from '../assets/Annual_Lightning_Report_2023-2024_Ex_Summary.pdf';

const LightningReport = () => {
  const report = {
    title: 'Annual Lightning Report 2023-2024 Ex Summary',
    pdfUrl: AnnualReport
  };

  return (
    <div className="lightning-report-container">
      <h2>Lightning Activity Report</h2>
      <div className="report-grid">
        <a href={report.pdfUrl} target="_blank" rel="noopener noreferrer" className="report">
          <span className="report-icon">📄</span>
          <span className="report-title">{report.title}</span>
          <span className="report-read-more">View Report</span>
        </a>
      </div>
    </div>
  );
};

export default LightningReport;
