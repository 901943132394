import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './chairman.css';
import chairmanImage from '../assets/patrons/Col_Sanjay_Srivastava.jpg'; // Replace with the actual image path

const Chairman = () => {
  return (
    <div className="container about-container">
      <h1 className="text-left my-4 about-title">Col Prof Sanjay Srivastava</h1>
      <section className="about-content">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <img src={chairmanImage} alt="Chairman" className="chairman-image" style={{marginTop: '2px'}} />
          </div>
          <div className="col-lg-6 col-md-12">
            <p className="about-text">
              Col Sanjay Srivastava is the founder and Chairman of CROPC. He is a visionary leader with a deep understanding of climate change and its impacts. He has been instrumental in developing and implementing innovative solutions to address climate-related challenges.
            </p>
            <p className="about-text">
              Col Srivastava has a proven track record of success in the field of disaster management. He has led numerous initiatives to improve the resilience of communities to natural disasters. He is also a passionate advocate for climate action.
            </p>
            <p className="about-text">
              Under his leadership, CROPC has made significant contributions to the development of India's first-ever Lightning Early Warning System, the Earthquake Early Warning System, and the Cyclone Risk Management System. He has also been instrumental in promoting the use of technology and data analytics to improve disaster preparedness and response.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chairman;
