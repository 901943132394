import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'; // Import Link
import { FaAndroid, FaApple } from 'react-icons/fa'; // Import icons from react-icons

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* CROPC Section */}
        <div className="footer-column">
          <h3 className="footer-header">CROPC</h3>
          <p className="footer-text">
            Climate Resilient Observing-Systems Promotion Council – abbreviated as
            CROPC has been formed as a Nonprofit organization under section 8 of
            Company Act 2013 to act as an interface between Central Early warning
            agencies, line departments, state, community, NGOs, and other stakeholders.
          </p>
        </div>

        {/* Useful Links Section */}
        <div className="footer-column">
          <h3 className="footer-header">Useful Links</h3>
          <ul className="footer-links">
            <li><Link to="/about/who-we-are" onClick={() => window.scrollTo(0, 0)}>About Us</Link></li>
            <li><Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link></li>
            <li><Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link></li>
            <li><Link to="/search-terms" onClick={() => window.scrollTo(0, 0)}>Search Terms</Link></li>
          </ul>
        </div>

        {/* Quick Links Section */}
        <div className="footer-column">
          <h3 className="footer-header">Quick Links (External)</h3>
          <ul className="footer-links">
            <li>
              <a href="https://mausam.imd.gov.in/" target="_blank" rel="noopener noreferrer">IMD</a>
            </li>
            <li>
              <a href="https://www.ndma.gov.in/" target="_blank" rel="noopener noreferrer">NDMA</a>
            </li>
            <li>
              <a href="https://www.moes.gov.in/" target="_blank" rel="noopener noreferrer">Ministry of Earth Sciences</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.lightening.live.damini&hl=en_IN" target="_blank" rel="noopener noreferrer">
                DAMINI <FaAndroid className="android-icon" />
              </a>
              <a href="https://apps.apple.com/in/app/damini-lightning-alert/id1502385645" target="_blank" rel="noopener noreferrer">
                <FaApple className="apple-icon" />
              </a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.imd.masuam&hl=hi" target="_blank" rel="noopener noreferrer">
                MAUSAM <FaAndroid className="android-icon" />
              </a>
              <a href="https://apps.apple.com/in/app/mausam/id1522893967" target="_blank" rel="noopener noreferrer">
                <FaApple className="apple-icon" />
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-column">
          <h3 className="footer-header">Contact</h3>
          <p className="footer-text">Call Us: +91-7667860470</p>
          <p className="footer-text">Email Us: cropcn@gmail.com</p>
          <div className="footer-social">
            <a href="https://www.facebook.com/p/Climate-Resilient-Observing-Systems-Promotion-Council-Cropc-100069992391844/?paipv=0&eav=AfYV-cUnmH5PLRCBC_yWP63zLbkPPd-bexaNLMW1dZc1GvhiQLJ0TDHnJRfsgehznzA&_rdr" className="social-icon" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-f"></i></a>
            <a href="https://x.com/cropclightning" className="social-icon" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
            <a href="https://www.youtube.com/@cropc_lightning" className="social-icon" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a>
            <a href="https://www.instagram.com/cropc_lightning_ngo/" className="social-icon" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
            <a href="https://www.linkedin.com/in/cropc-inter-governmental-scientific-organization-358031247/" className="social-icon" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2020 - 2024 CROPC. All rights reserved | Design by <span className="footer-design">CROPC</span></p>
      </div>
    </footer>
  );
};

export default Footer;
