import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const mailtoLink = `mailto:cropcn@gmail.com?subject=Message from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-container container">
      <div className="row">
        <div className="col-lg-6 order-2 order-lg-1"> 
          <h2>Contact Us</h2>
          <p className="contact-subtitle">We’re here to help with any inquiries</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="you@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="5"
                placeholder="Your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-block">Send Message</button>
          </form>
        </div>
        <div className="col-lg-6 order-1 order-lg-2">
          <div className="map-container">
            <iframe
              title="Institute of Social Sciences Map"
              src="https://maps.google.com/maps?q=Institute%20of%20Social%20Sciences,%20Vasant%20Kunj,%20New%20Delhi,%20India&t=&z=14&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              style={{ border: 0, width: '100%', height: '100%' }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="row contact-info">
        <div className="col-12">
          <div className="contact-info-bar d-flex flex-column flex-md-row justify-content-between align-items-start">
            <div className="contact-info-item">
              <i className="bi bi-geo-alt-fill contact-icon"></i>
              <div>
                <strong>Location:</strong>
                <p>Institute of Social Sciences,<br />Vasant Kunj, New Delhi, India</p>
              </div>
            </div>
            <div className="contact-info-item">
              <i className="bi bi-envelope-fill contact-icon"></i>
              <div>
                <strong>Email:</strong>
                <p>cropcn@gmail.com</p>
              </div>
            </div>
            <div className="contact-info-item">
              <i className="bi bi-telephone-fill contact-icon"></i>
              <div>
                <strong>Phone:</strong>
                <p>+91-7667860470</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;