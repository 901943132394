import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './whoweare.css';
import aboutImage1 from '../assets/about/1.jpg'; // Replace with the actual image path
import aboutImage2 from '../assets/about/2.png'; // Replace with the actual image path
import aboutImage3 from '../assets/about/3.png';
import aboutImage4 from '../assets/about/4.png'; // Replace with the actual image path

const WhoWeAre = () => {
  return (
    <div className="container about-container">
      <h1 className="text-left my-4 about-title">Who We Are</h1>
      <section className="about-content">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <p className="about-text">
              Climate Resilient Observing-Systems Promotion Council – abbreviated as CROPC is a Nonprofit organization under section 8 of the Company Act 2013. This is a group of scientists, bureaucrats, Disaster Management professionals, researchers and students with an aim to bring technology-driven innovative solutions to disasters and development. Initially,
              CROPC was formed to act as an interface between Central Early warning agencies, line departments, state, Community, NGOs and other stakeholders.
            </p>
            <p className="about-text">
              CROPC has DG IMD as its ex officio patron followed by other eminent scientists like Dr KJ Ramesh Former DG IMD, Mr. PP Shrivastav, IAS, Member Advisory Board NDMA, Mr Anil Kumar Sinha, IAS, Former VC BSDMA, Dr VS Prakash, Member Karnataka Lake Authorities, Prof AK Gosain etc.
              CROPC has a Memorandum of Understanding with the India Meteorological Department (IMD), Ministry of Earth Sciences, Government of India to use its scientific knowledge.
              CROPC is mandated to function under the regulatory framework as the Center for Promotion of Climate Resilient Observing Systems and public notification systems for various hazards due to Climate Change extremities and as a link between IMD and others for the Lightning Resilient India Campaign. Therefore,
              Lightning Resilient India Campaign, a nationwide campaign, was launched by CROPC-IMD on 26 March 2019 at IIT Delhi. CROPC can be seen at its website <a href="http://www.cropc.org" target="_blank" rel="noopener noreferrer">www.cropc.org</a>.
            </p>
          </div>
          <div className="col-lg-6 col-md-12">
            <div id="aboutCarousel" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#aboutCarousel" data-slide-to="0" className="active"></li>
                <li data-target="#aboutCarousel" data-slide-to="1"></li>
                <li data-target="#aboutCarousel" data-slide-to="2"></li>
                <li data-target="#aboutCarousel" data-slide-to="3"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={aboutImage1} alt="First slide" className="d-block w-100 carousel-image" />
                </div>
                <div className="carousel-item">
                  <img src={aboutImage2} alt="Second slide" className="d-block w-100 carousel-image" />
                </div>
                <div className="carousel-item">
                  <img src={aboutImage3} alt="Third slide" className="d-block w-100 carousel-image" />
                </div>
                <div className="carousel-item">
                  <img src={aboutImage4} alt="Fourth slide" className="d-block w-100 carousel-image" />
                </div>
              </div>
              <a className="carousel-control-prev" href="#aboutCarousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#aboutCarousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhoWeAre;
