// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import ObservingSystem from './components/ObservingSystem';
import LightningResilientIndiaCampaign from './components/LightningResilientIndiaCampaign';
import CropcBlog from './components/CropcBlog';
import LightningReport from './components/LightningReport';
import NewsAndMedia from './components/NewsAndMedia';
import Contact from './components/Contact';
import WhoWeAre from './components/whoweare'; // Import WhoWeAre component
import WhatWeDo from './components/whatwedo'; 
import Vision from './components/vision'; // Import Vision component
import Mission from './components/mission'; 
import GoverningCouncil from './components/governingcouncil'; 
import Chairman from './components/chairman'; 
import ManagementTeam from './components/managementteam'; 
import StatesTeam from './components/statesteam'; 

const App = () => {
    return (
        <Router>
            <Header />
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />}>
                  <Route path="who-we-are" element={<WhoWeAre />} />
                  <Route path="what-we-do" element={<WhatWeDo />} />
                  <Route path="vision" element={<Vision />} />
                  <Route path="mission" element={<Mission />} />
                  <Route path="governing-council" element={<GoverningCouncil />} />
                  <Route path="chairman" element={<Chairman />} />
                  <Route path="management-team" element={<ManagementTeam />} />
                  <Route path="states-team" element={<StatesTeam />} />
                  {/* Add routes for other components */}
                </Route>
                <Route path="/services" element={<Services />} />
                <Route path="/observing-system" element={<ObservingSystem />} />
                <Route path="/lightning-resilient-india-campaign" element={<LightningResilientIndiaCampaign />} />
                <Route path="/cropc-blog" element={<CropcBlog />} />
                <Route path="/lightning-report" element={<LightningReport />} />
                <Route path="/news-and-media" element={<NewsAndMedia />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;