import React from 'react';

const WhatWeDo = () => {
  return (
    <div>
      <h2><b>What We Do</b></h2>
      <p>
        CROPC focuses on promoting climate-resilient observing systems and public notification systems for various hazards due to climate change extremities. We aim to bridge the gap between the India Meteorological Department (IMD) and other stakeholders, including:
      </p>
      <ul>
        <li>Central Early Warning Agencies</li>
        <li>Line Departments</li>
        <li>State Governments</li>
        <li>Communities</li>
        <li>NGOs</li>
        <li>Other Stakeholders</li>
      </ul>
      <p>
        Our key activities include:
      </p>
      <ul>
        <li>Development of India’s first-ever Lightning Early Warning System in India in Jharkhand in 2008-2011 and came out with a mobile app. The same was later replicated in Andhra Pradesh, Odisha, West Bengal and Karnataka. Col Sanjay Srivastava, founder of CROPC was instrumental in showcasing the same with the India Met Department and IMD started Lightning forecast from 01 April 2019.</li>
        <li>Development of Earthquake Early Warning System and On-site EEW Systems</li>
        <li>Cyclone Risk Management through proactive measures using technology and data analytics. Research in fulminology and atmospheric science.</li>
        <li>Multihazard Early Warning Systems for North Eastern states.</li>
        <li>Pre-flood indicators for advance preparedness for flood</li>
        <li>Extreme weather early warning-based proactive solutions and Disaster Resilient Shelters.</li>
        <li>State of art Command, Control, and Communication Centers with Decision Support systems.</li>
        <li>Public notification and dissemination system. Designing of performance-based buildings and infrastructures.</li>
        <li>Our Chairman authored India’s National program on Lightning Risk Management for National Disaster Management Authority NDMA. Also rendering services as Technical Advisor on the subject to NDMA and many states.</li>
        <li>Initiating Citizen Science approach for Lightning resilience in India as Lightning Resilient India Campaign – an end-to-end solution with IMD, Ministry of Earth Science, INSRO, NDMA, all state governments, INGOs local NGOs and academia.</li>
        <li>Lightning Protection Device- promote and advocate the installation of Lightning Conductors and Arresters. Created Lightning- safe schools (40000 plus), Hospitals (6000+), Cyclone shelters (891), villages, city (Deoghar Jharkhand) etc.</li>
        <li>Capacity building and training on lightning to stakeholders and community.</li>
        <li>Promotion of research and development through MOES, ISRO and local academia.</li>
        <li>Instrumental in development and commissioning of India’s first Glacial Lake Outburst Early Warning System at Chamoli Uttarakhand.</li>
      </ul>
    </div>
  );
};

export default WhatWeDo;
